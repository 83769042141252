<template>
  <div class="synopsis">
    <img src="@/assets/mobile/cooperate/banner.jpg" class="banner" />
    <span class="about_title">合作伙伴 智创未来</span>
    <div class="center">
      <div class="center_title">华汇能为你提供</div>
      <div class="center_wrapper">
        <div class="center_content">
          <div class="center_item">
            <img
              src="@/assets/mobile/cooperate/icon1.png"
              class="center_item_img"
            />
            <div class="center_item_text">优质服务</div>
            <div class="center_item_sub">
              具有一站式的项目实施落地和持续服务的能力, 1:1服务终端客户
            </div>
          </div>
          <div class="center_item">
            <img
              src="@/assets/mobile/cooperate/icon2.png"
              class="center_item_img"
            />
            <div class="center_item_text">产品培训</div>
            <div class="center_item_sub">
              能为您提供商务支持和线上、线下产品服务培训
            </div>
          </div>
          <div class="center_item">
            <img
              src="@/assets/mobile/cooperate/icon3.png"
              class="center_item_img"
            />
            <div class="center_item_text">技术支持</div>
            <div class="center_item_sub">
              售前、售后技术支持快速响应, 让您后顾无忧
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contactus">
      <div class="contactus_title">联系我们</div>
      <div class="contactus_sub">留下联系方式，以便为您提供更好的服务</div>
      <div class="contactus_form">
        <van-form ref="formLabel" :model="form" :key="formKey">
          <!-- 手机号码 -->
          <van-field
            label-align="top"
            label="公司名称:"
            v-model="form.company"
            :rules="telRules"
            input-align="top"
            required
            class="contactus_input"
          ></van-field>
          <van-field
            label="您的姓名："
            :rules="nameRules"
            v-model="form.name"
            required
            class="contactus_input"
          >
          </van-field>

          <van-field
            label="联系电话"
            :rules="phoneRules"
            v-model="form.phone"
            required
            class="contactus_input"
          >
          </van-field>
          <van-field
            label="您的诉求:"
            v-model="form.ask"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="100"
            show-word-limit
            class="contactus_text"
          >
          </van-field>
        </van-form>

        <div class="contactus_bto" @click="çommit()">提交</div>
      </div>
    </div>
    <div class="partner">
      <div class="industry_title">合作伙伴</div>
      <div class="partner_wrapper">
        <div class="partner_item">
          <img src="@/assets/mobile/partners/1.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/2.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/3.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/4.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/5.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/6.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/7.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/8.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/9.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/10.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/11.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/12.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/13.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/14.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/15.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/16.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/17.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/18.png" class="partner_img" />
        </div>
        <div class="partner_item" style="margin-right: 0.3rem">
          <img src="@/assets/mobile/partners/19.png" class="partner_img" />
        </div>
        <div class="partner_item">
          <img src="@/assets/mobile/partners/20.png" class="partner_img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import http from "../utils/http";
export default {
  data() {
    const valiatephone = (rule, value, callback) => {
      var res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;

      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!res.test(value)) {
        callback(new Error("手机号格式错误，请重新填写"));
      } else {
        callback();
      }
    };
    return {
      formKey: 0,
      form: {},
      telRules: [
        {
          required: true,
          message: "请填写公司名称",
          trigger: "onBlur",
        },
      ],
      phoneRules: [
        { required: true, message: "手机号码不能为空" },
        {
          // 自定义校验规则
          validator: (value) => {
            return /^[1][3,4,5,6.7,8,9][0-9]{9}$/.test(value);
          },
          message: "请输入正确格式的手机号码",
          trigger: "onBlur",
        },
      ],
      nameRules: [
        {
          required: true,
          message: "姓名不能为空",
          trigger: "onBlur",
        },
      ],

      rules: {
        company: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, validator: valiatephone, trigger: "blur" }],
      },
    };
  },

  components: {},
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      };
    },
  },
  methods: {
    çommit() {
      this.$refs.formLabel
        .validate()
        .then(() => {
          var map = {};
          map = this.form;
          if (!this.form.ask) {
            map.ask = "无";
          }
          http.post("/mail/home_send", this.form).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.form = {};
              // this.$refs.formLabel.clearValidate();
              this.formKey++;
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});

      // this.$refs["formLabel"].validate((valid) => {
      //   if (valid) {
      //     var map = {};
      //     map = this.form;
      //     if (!this.form.ask) {
      //       map.ask = "无";
      //     }
      //     http.post("/mail/home_send", this.form).then((res) => {
      //       if (res.code == 200) {
      //         this.$message.success("提交成功");
      //         this.form = {};
      //         this.$refs["formLabel"].clearValidate();
      //       } else {
      //         this.$message.error(res.message);
      //       }
      //     });
      //   }
      // });
    },
  },
};
</script>

<style scoped>
.center {
  width: 100%;
  padding: 2.625rem 0 1.9375rem 0;
  background: #fbfbfbff;
  margin: 0 auto;
}
.center_title {
  font-size: 1.4375rem;
  color: #030303ff;
  font-weight: bold;
}
.title {
  font-size: 1.4375rem;
  color: #333333ff;
  font-weight: bold;
  margin-top: 2.625rem;
  margin-bottom: 1.3125rem;
}
.center_wrapper {
  width: 100%;
  overflow: scroll;
}
.center_wrapper::-webkit-scrollbar {
  display: none;
}
.center_content {
  display: flex;
  width: 32.25rem;
  margin: 1.9375rem 1rem 0 1rem;
  align-items: center;
  justify-content: space-between;
}
.center_content::-webkit-scrollbar {
  display: none;
}
.center_item {
  width: 10rem;
  height: 13.625rem;
  padding-top: 1.9375rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.center_item_img {
  width: 3.625rem;
  height: 3.625rem;
  margin-bottom: 1.125rem;
}
.center_item_text {
  font-size: 1rem;
  font-weight: bold;
  color: #333333ff;
}

.v-enter-active {
  animation: myAni 1s;
}

.v-leave-active {
  animation: myAni 0.2s reverse;
}

@keyframes myAni {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0rem);
  }
}
.bottom {
  width: 100%;
  height: 111.75rem;
  padding-top: 6.25rem;
  box-sizing: border-box;
  position: relative;
}
.glory {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bottom_title {
  font-size: 3.125rem;
  color: white;
  text-align: center;
}
.bottom_imglist {
  width: 101.75rem;
  margin: 6.25rem auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.bottom_item {
  width: 32.625rem;
  height: 20.75rem;
  margin-bottom: 1.875rem;
}
.banner {
  width: 100%;
  height: 29.4375rem;
}
.about_title {
  position: absolute;
  top: 6.6875rem;
  left: 0;
  right: 0;
  font-size: 1.6875rem;
  color: #ffffff;
}
.center_item_sub {
  width: 140px;
  font-size: 0.875rem;
  color: #333333ff;
  text-align: center;
  margin-top: 0.75rem;
  vertical-align: middle;
}

.industry_title {
  font-size: 1.5625rem;
  color: #030303ff;
  font-weight: bold;
}
.industry_tips {
  font-size: 1.75rem;
  color: #333333ff;
  margin-top: 0.875rem;
}
.industry_swiper {
  margin: 6.25rem 0 10rem;
}
.industry_item {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 31.875rem;
  height: 47.625rem;
  padding-top: 4.25rem;
  box-sizing: border-box;
}
.industry_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.is-active .industry_item {
  width: 36.25rem !important;
}
.industry_swiper_title {
  font-size: 2.9375rem;
  color: white;
  font-weight: 500;
}
.industry_swiper_tips {
  font-size: 1.5625rem;
  color: white;
  margin: 1rem 0 1.25rem;
  font-weight: 500;
}
.industry_swiper_more {
  display: flex;
  align-items: center;
  font-size: 1.4375rem;
  justify-content: center;
  color: rgba(250, 250, 250, 0.6);
}
.industry_swiper_moreicon {
  width: 0.375rem;
  height: 0.6875rem;
  margin-left: 0.625rem;
}

.contactus {
  width: 100%;
  padding-top: 2.625rem;
  padding-bottom: 43px;
  box-sizing: border-box;
  background: #f7f7feff;
}
.contactus_title {
  font-size: 1.4375rem;
  font-weight: bold;
  color: #030303ff;
}
.contactus_sub {
  font-size: 1.0625rem;
  color: #6c6c6fff;
  margin-top: 0.75rem;
}
.contactus_form {
  width: 20.5rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0rem 0rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.16);
  padding: 33px 0.5625rem 1.9375rem 0.5625rem;
  margin: 1.9375rem 1.9375rem 0;
  border-radius: 10px;
}
.contactus_form .contactus_input::v-deep .van-field__value {
  border: 1px solid #afafaf;
  line-height: 2.3125rem;
  padding-left: 0.3125rem;
}

.contactus_form .contactus_text::v-deep .van-field__value {
  border: 1px solid #afafaf;
  height: auto;
  line-height: 1.5rem;
  padding: 0.3125rem;
}
.contactus_form ::v-deep .van-cell {
  display: flow-root;
  min-height: 100px;
  padding-bottom: 0;
  padding-top: 0;
}

.contactus_form::v-deep .van-field__label {
  font-size: 15px;
  padding-bottom: 0.625rem;
}

.contactus_form .van-cell:after {
  position: relative;
}
.contactus_form ::v-deep .van-field__error-message {
  color: #ee0a24;
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  position: absolute;
}
.contactus_item {
  margin-top: 0.625rem;
}
.contactus_item_title {
  text-align: left;
  font-size: 1rem;
  color: #000000ff;
  margin-bottom: 1rem;
}
::v-deep .el-input {
  height: 2.3125rem;
}
.contactus_bto {
  width: 17.4375rem;
  height: 2.625rem;
  background: #448ef7;
  border-radius: 1.5625rem 1.5625rem 1.5625rem 1.5625rem;
  margin: 4.9375rem auto 0;
  color: white;
  cursor: pointer;
  line-height: 2.625rem;
  margin-top: 2rem;
  text-align: center;
}
.partner {
  padding-top: 2.625rem;
  width: 100%;
  background: white;
}
.partner_wrapper {
  height: 31.4375rem;
  display: flex;
  flex-wrap: wrap;
  padding: 2.625rem 1rem;
  align-content: space-between;
}
.partner_item {
  background: none;
  width: 7rem;
  height: 3.375rem;
  margin-right: auto;
}
.partner_img {
  width: 7rem;
  height: 3.375rem;
}
</style>