<template>
  <div>
    <div
      :class="[distance > 0 ? 'adsorption' : 'header']"
      @mouseover="cancelDiglog"
    >
      <div class="header_tab_left">
        <img
          src="../assets/logonew.png"
          class="header_logo"
          @click="switchPage(firstPage)"
        />
        <div class="header_tab">
          <div
            v-for="item in navi"
            :key="item.index"
            class="tab"
            @click="switchPage(item)"
          >
            <div
              @mouseover.stop="mousein(item.index)"
              :class="[
                selectIndex == item.index ? 'header_tab_select' : '',
                distance > 0 && selectIndex == item.index
                  ? 'header_tab_select2'
                  : '',
              ]"
            >
              {{ item.name }}
            </div>
            <div
              @mouseleave="cancelDiglog"
              @mouseover.stop="mousein(2)"
              v-if="item.index == 2"
              :class="[
                'solution',
                isProgramme ? 'showdropdown2' : 'nonedropdown2',
              ]"
            >
              <div
                v-for="item in solutionList"
                :key="item.name"
                @click="skip(item.url)"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              @mouseleave="cancelDiglog"
              @mouseover.stop="mousein(5)"
              v-if="item.index == 5"
              :class="['open', isOpen ? 'showdropdown3' : 'nonedropdown3']"
            >
              <div
                v-for="item in openPlatform"
                :key="item.name"
                @click="openWindow(item.url)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <img :src="languageIcon" class="language" /> -->
    </div>
    <!-- 产品中心 -->
    <div
      :class="['dropdown', isProduct ? 'showdropdown' : 'nonedropdown']"
      @mouseleave="cancelDiglog"
    >
      <div
        v-for="item in productList"
        :key="item.name"
        class="dropdown_item"
        @click="skip(item.url)"
      >
        <img :src="item.img" class="dropdown_img" />
        <div class="dropdown_title">{{ item.name }}</div>
      </div>
      <div class="dropdown_more" @click="skip('/product')">
        <div>查看更多</div>
        <img src="@/assets/product/right.png" class="dropdown_righticon" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.png";
import en from "../assets/en.png";
import en2 from "../assets/en2.png";

import p1 from "../assets/product/p1.png";
import p2 from "../assets/product/p2.png";
import p3 from "../assets/product/p3.png";
import p4 from "../assets/product/p4.png";
import p5 from "../assets/product/p5.png";
import p6 from "../assets/product/p6.png";
import p7 from "../assets/product/p7.png";
import p8 from "../assets/product/p8.png";
import p9 from "../assets/product/p9.png";
import p10 from "../assets/product/p10.png";
import p11 from "../assets/product/p11.png";
export default {
  data() {
    return {
      distance: 0, //滚动距离
      logoIcon: logo, //logo图标
      languageIcon: en, //语音图标
      selectIndex: 0, //当前所在索引
      isProduct: false, //是否显示产品中心
      isProgramme: false, //是否显示解决方案
      isOpen: false, //是否显示开放平台
      firstPage: {
        name: "首页",
        index: 0,
        url: "/home",
      },
      navi: [
        {
          name: "首页",
          index: 0,
          url: "/home",
        },
        {
          name: "产品中心",
          index: 1,
          url: "/product",
        },
        {
          name: "解决方案",
          index: 2,
        },
        {
          name: "关于华汇",
          index: 3,
          url: "/about",
        },
        {
          name: "商务合作",
          index: 4,
          url: "/cooperate",
        },
        {
          name: "开放平台",
          index: 5,
        },
      ],

      productList: [
        {
          img: p1,
          name: "证码通RC13",
          url: "/rc13",
        },
        {
          img: p2,
          name: "证码通RC20",
          url: "/pda",
        },
        {
          img: p3,
          name: "证码通RC10",
          url: "/rc10",
        },
        {
          img: p4,
          name: "智能电子学生证",
          url: "/studentCard",
        },
        {
          img: p5,
          name: "智能发卡收银机AT21",
          url: "/at21",
        },
        {
          img: p6,
          name: "双屏⼈证核验终端AT31",
          url: "/at31",
        },
        {
          img: p7,
          name: "双屏收银机ST-B2",
          url: "/stb2",
        },
        {
          img: p8,
          name: "实名制平板RC14",
          url: "/rc14",
        },
        // {
        //   img: p9,
        //   name: "CPU边缘服务器",
        // },
        // {
        //   img: p10,
        //   name: "NPU边缘服务器",
        // },
        // {
        //   img: p11,
        //   name: "通道闸机K1",
        // },
      ],

      solutionList: [
        {
          name: "智慧园区",
          url: "/park",
        },
        {
          name: "智慧校园",
          url: "/campus",
        },
        {
          name: "智慧交通",
          url: "/traffic",
        },
        {
          name: "智慧工地",
          type: 2,
          url: "/construction",
        },
        {
          name: "智慧厅店",
          type: 2,
          url: "/construction",
        },
        {
          name: "智慧新零售",
          type: 2,
          url: "/construction",
        },
        {
          name: "智慧公租房",
          url: "/public",
        },
      ],

      openPlatform: [
        {
          name: "人员管理平台",
          url: "http://web.verification.srthinker.com/",
        },
        {
          name: "证码核验平台",
          url: "http://web.antiepidemic.srthinker.com/",
        },
      ],
    };
  },
  watch: {
    distance: function (e) {
      if (e > 0) {
        this.logoIcon = logo2;
        this.languageIcon = en2;
      } else {
        this.logoIcon = logo;
        this.languageIcon = en;
      }
    },

    $route: {
      handler() {
        var path = this.$route.path;
        var cindex = this.$route.meta.index;
        var index = this.navi.findIndex((item) => item.url == path);
        if (index > -1) {
          this.selectIndex = index;
        } else {
          this.selectIndex = cindex;
        }
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
  },
  created() {
    var url = this.$route.path;
    var cindex = this.$route.meta.index;
    var value = this.navi.find((item) => item.url == url);
    if (value) {
      this.selectIndex = value.index;
    } else {
      this.selectIndex = cindex;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.distance = scrollTop;
    },

    // 切换页面
    switchPage(item) {
      if (!item.url) {
        return;
      }
      if (this.selectIndex == item.index) {
        return;
      }
      this.isProduct = false;
      this.$router.push({
        path: item.url,
      });
    },

    // 鼠标移入
    mousein(index) {
      switch (index) {
        case 1:
          this.isProduct = true;
          break;
        case 2:
          this.isProgramme = true;
          break;
        case 5:
          this.isOpen = true;
          break;
        default:
          break;
      }
    },

    // 取消弹窗
    cancelDiglog() {
      this.isProduct = false;
      this.isProgramme = false;
      this.isOpen = false;
    },

    // 跳转页面
    skip(url) {
      var curl = this.$route.path;
      if (!url) {
        return;
      }
      if (url == curl) {
        return;
      }
      this.isProduct = false;
      this.isProgramme = false;
      this.isOpen = false;
      this.$router.push({
        path: url,
      });
    },

    // 打开新的窗口
    openWindow(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 5rem;
  position: fixed;
  color: #ffffff;
  display: flex;
  z-index: 999;
  padding: 0 3.75rem 0 12.75rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
}
.adsorption {
  width: 100%;
  height: 5rem;
  background: white;
  padding: 0 3.75rem 0 12.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.header_tab_left {
  display: flex;
  height: 100%;
  align-items: center;
}
.header_logo {
  cursor: pointer;
  height: 2.3125rem;
}
.header_tab {
  margin-left: 12.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
}
/* .header_tab div {
  margin-right: 60px;
  text-align: center;
} */
.language {
  width: 1.75rem;
  height: 1.75rem;
}
.header_tab_select {
  color: rgba(250, 250, 250, 0.6);
  position: relative;
}
.header_tab_select2 {
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}
.header_tab_select2::after {
  content: "";
  position: absolute;
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 0.375rem;
  background: #448ef7 !important;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.75rem;
}
.header_tab_select::after {
  content: "";
  position: absolute;
  width: 2.5rem;
  height: 0.25rem;
  background: #ffffff;
  opacity: 1;
  left: 50%;
  border-radius: 0.375rem;
  transform: translateX(-50%);
  bottom: 0.75rem;
}

.dropdown {
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100%;
  background: white;
  z-index: 999;
  display: flex;
  padding: 0 8.5rem;
  box-sizing: border-box;
  align-items: center;
  flex-wrap: wrap;
}
.nonedropdown {
  transition: height 0.5s ease-out;
  height: 0;
  overflow: hidden;
}
.showdropdown {
  height: 30rem;
  transition: height 0.5s ease-out;
  overflow: hidden;
}
.nonedropdown2 {
  transition: height 0.5s ease-out;
  height: 0;
  overflow: hidden;
}
.showdropdown2 {
  height: 20.5625rem;
  transition: height 0.5s ease-out;
  overflow: hidden;
}
.nonedropdown3 {
  transition: height 0.5s ease-out;
  height: 0;
  overflow: hidden;
}
.showdropdown3 {
  height: 6.5rem;
  transition: height 0.5s ease-out;
  overflow: hidden;
}
.dropdown_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000ff;
  position: relative;
  width: 17rem;
  height: 15rem;
}
.dropdown_item::after {
  content: "";
  width: 0.0625rem;
  height: 7.5rem;
  position: absolute;
  right: 0;
  top: 4.8125rem;
  background: #e5e5e5ff;
}
.dropdown_item:hover {
  color: #60a6ecff;
}
.dropdown_more {
  width: 17rem;
  height: 15rem;
  display: flex;
  color: #000000ff;
  font-size: 1.0625rem;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.dropdown_more:hover {
  color: #60a6ecff;
}
.dropdown_img {
  width: 8.75rem;
  height: 8.75rem;
}
.dropdown_title {
  font-size: 1.0625rem;
  margin-top: 1rem;
}
.dropdown_righticon {
  width: 0.4375rem;
  height: 0.8125rem;
  margin-left: 0.75rem;
}
.tab {
  height: 100%;
  line-height: 5rem;
  margin-right: 3.75rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.solution {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: -2.625rem;
  top: 5rem;
  width: 9.5rem;
  border-radius: 0.625rem;
  background: white;
  z-index: 999;
}
.solution div {
  color: #000000ff;
  line-height: normal;
  font-size: 1rem;
  height: 2.875rem;
  line-height: 2.875rem;
}
.open {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: -2.625rem;
  top: 5rem;
  width: 9.5rem;
  border-radius: 0.625rem;
  background: white;
  z-index: 999;
}
.open div {
  color: #000000ff;
  line-height: normal;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 3.25rem;
}
</style>
