<template>
  <div>
    <div class="title">智能自闭阀</div>
    <div class="gas_operate">
      <div>操作:</div>
      <div class="gas_operate_c">
        <el-button type="primary" :loading="isload" @click="onoff(true)"
          >开阀</el-button
        >
        <el-button type="primary" :loading="isload3" @click="onoff(false)"
          >闭阀</el-button
        >
        <el-button type="primary" :loading="isload2" @click="setTime"
          >设置时间</el-button
        >
        <div class="gas_top_select">
          <div>调节压强档:</div>
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="selectposition"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="gas_status">
      <div class="gas_status_top">
        <div class="gas_status_title">设备运行状态:</div>
        <el-button type="primary" class="gas_status_button" @click="getDevice"
          >刷新状态</el-button
        >
      </div>
      <div
        class="gas_status_content"
        v-for="(item, index) in detail"
        :key="index"
      >
        <div class="gas_status_content_q">{{ item.propName }}:</div>
        <div>{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      TimeStamp: "", //时间戳
      HmacSHA1: "", //加密
      detail: [], //具体信息
      token: "", //token
      equipmentName: "", //设备名称
      productKey: "", //设备key
      isload: false,
      isload2: false,
      isload3:false,
      value: "",
      options: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
      ],
    };
  },

  created() {
    this.login();
  },

  methods: {
    // 默认登录
    login() {
      var that = this;
      axios
        .post("/sys/dev/user/login", {
          autoLogin: true,
          userLogin: "sixiang",
          userPassword: "qwe123",
        })
        .then(function (response) {
          var data = response.data;
          if (data.code == 200) {
            // HmacSHA1 加密
            let TimeStamp = new Date().getTime();
            let token = data.data.token;
            // console.log(token);
            let src = `token=${token}&&timeStamp=${TimeStamp}`;
            let HmacSHA1 = CryptoJS.HmacSHA1(
              src,
              "90d2fca50ea8ed5472c5776c9fc53638"
            ).toString();

            that.TimeStamp = TimeStamp;
            that.HmacSHA1 = HmacSHA1;

            that.token = data.data.token;

            that.getDevice();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getDevice() {
      var that = this;
      axios({
        method: "post",
        url: "/sys/dev/product/equipment/query",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: {
          equipmentId: "1830381668209262592",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          that.equipmentName = res.data.data.equipmentName;
          that.productKey = res.data.data.productKey;

          that.getDeviceInfo();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 获取设备
    getDeviceInfo() {
      var that = this;
      axios({
        method: "post",
        url: "/sys/dev/device/runtime",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: {
          deviceName: that.equipmentName,
          productKey: that.productKey,
          type: "prop",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          that.detail = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 开关阀门
    onoff(value) {
      if (value) {
        this.isload = true;
      } else {
        this.isload3 = true;
      }
      var data = {
        command: {
          status_open_controll: value,
        },
        deviceName: this.equipmentName,
        functionName: "自闭状态控制",
        identifier: "status_open_controll",
        physicalServiceId: "1830404466801639424",
        productKey: this.productKey,
      };

      this.escalation(data);
    },

    // 设置时间
    setTime() {
      this.isload2 = true;
      let TimeStamp = new Date().getTime();
      var data = {
        command: {
          time_set: TimeStamp,
        },
        deviceName: this.equipmentName,
        functionName: "设置时间",
        identifier: "time_set",
        physicalServiceId: "1830404466810028032",
        productKey: this.productKey,
      };

      this.escalation(data);
    },

    // 调节档位
    selectposition(value) {
      var data = {
        command: {
          pressure_range_controll: value,
        },
        deviceName: this.equipmentName,
        functionName: "压强档控制",
        identifier: "pressure_range_controll",
        physicalServiceId: "1830404466818416640",
        productKey: this.productKey,
      };

      this.escalation(data);
    },

    // 上报操作
    escalation(data) {
      var that = this;
      axios({
        method: "post",
        url: "/sys/dev/device/server/invokeWithReply",
        headers: {
          Authorization: "Bearer " + that.token,
          Sign: that.HmacSHA1,
          Timestamp: that.TimeStamp,
        },
        data: data,
      }).then((res) => {
        this.isload = false;
        this.isload2 = false;
        this.isload3 = false;
        if (res.data.code == 200) {
          if (res.data.data.code == 4025) {
            that.$message.warning(res.data.data.msg);
          }
        } else {
          that.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  font-size: 30px;
  margin-top: 50px;
}

.gas_top {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.gas_top div {
  margin-left: 30px;
  font-size: 26px;
}

.gas_operate {
  margin-top: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 26px;
  box-sizing: border-box;
}

.gas_status {
  padding-left: 30px;
  box-sizing: border-box;
}

.gas_status_title {
  font-size: 26px;
}

.gas_status_content {
  margin-top: 20px;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.gas_status_content_q {
  width: 150px;
  margin-right: 20px;
  text-align: left;
}

.gas_top_select {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.gas_status_top {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.gas_status_button {
  width: 100px;
  margin-left: 20px;
}
.gas_operate_c {
  display: flex;
  align-items: center;
}
</style>