<template>
  <div>
    <div class="content">
      <div class="map">
        <baidu-map
          :center="center"
          zoom="16"
          :scroll-wheel-zoom="true"
          style="width: 100%; height: 100%"
          @ready="handler"
          @click="getClickInfo"
          @moving="syncCenterAndZoom"
          @moveend="syncCenterAndZoom"
          @zoomend="syncCenterAndZoom"
        >
          <bm-marker
            :position="markerPoint"
            :dragging="false"
            :icon="{
              url: require('../../assets/mobile/about/location.png'),
              size: { width: 18, height: 23 },
            }"
          ></bm-marker>
        </baidu-map>
      </div>
    </div>
    <div class="way">
      <div class="title">广州华汇信息科技有限公司</div>
      <div class="way_content">
        <div class="way_item">
          <img src="@/assets/about/l.png" class="way_icon" />
          <div class="way_label">公司地址:</div>
          <div class="way_answer">广州市黄埔区开创大道2819号6楼612房</div>
        </div>
        <div class="way_item">
          <img src="@/assets/about/e.png" class="way_icon" />
          <div>官方邮箱:</div>
          <div class="way_answer">sales@huahuico.com</div>
        </div>
        <div class="way_item">
          <img src="@/assets/about/p.png" class="way_icon" />
          <div>公司电话:</div>
          <div class="way_answer">020-8092 2872</div>
        </div>
        <div class="way_item">
          <img src="@/assets/about/t.png" class="way_icon" />
          <div>销售电话:</div>
          <div class="way_answer">17600058551（陈经理）</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lng: 113.462669, lat: 23.180724 }, //中心点坐标
      markerPoint: { lng: 113.462773, lat: 23.181151 },
    };
  },
};
</script>

<style scoped>
.content {
  width: 20.5rem;
  height: 9.75rem;
  margin: 0 auto 2.625rem;
  display: flex;
  align-items: center;
}
.map {
  width: 100%;
  height: 100%;
}
.way {
  width: 20.5rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  align-items: flex-start;
}
.way_content {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5625rem;
}
.way_item {
  display: flex;
  color: #717473ff;
  font-size: 0.9375rem;
  margin-top: 1.8125rem;
  text-align: left;
}
.way_label {
  width: 4.5625rem;
}
.way_answer {
  font-size: 0.9375rem;
  color: #000000ff;
  margin-left: 0.375rem;
  width: auto;
}
.title {
  font-size: 1.1875rem;
  color: #030303ff;
  font-weight: bold;
}
.way_icon {
  width: 1.4375rem;
  height: 1.4375rem;
  margin-right: 0.25rem;
}
::v-deep .BMap_cpyCtrl {
  display: none;
}
::v-deep .anchorBL {
  display: none;
}
</style>