<template>
  <div class="underside">
    <div class="underside_box">
      <div class="underside_navi">
        <div
          v-for="item in navigationList"
          :key="item.name"
          class="underside_navi_item"
        >
          <div class="underside_navi_headline">{{ item.name }}</div>
          <div
            v-for="citem in item.chirdren"
            :key="citem.name"
            @click="skipUrl(citem)"
            class="underside_navi_subheading"
          >
            {{ citem.name }}
          </div>
        </div>
        <div class="underside_company">
          <div class="underside_navi_headline">广州华汇信息科技有限公司</div>
          <div class="underside_navi_subheading">公司电话：020-8092 2872</div>
          <div class="underside_navi_subheading">
            销售电话：17600058551（陈先生）
          </div>
          <div class="underside_navi_subheading">
            官方邮箱：sales@huahuico.com
          </div>
          <div class="underside_navi_subheading">
            公司地址：广州市黄埔区开创大道2819号6楼612房
          </div>
        </div>
      </div>
      <div class="underside_filings">
        <img src="@/assets/copyright.png" class="underside_filings_icon" />
        <div class="underside_copyright">
          2023 广州华汇信息科技有限公司 Guangzhou Huahui Information Technology Co.,Ltd.
        </div>
        <div @click="record" class="record">备案号： 粤ICP备19071903号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigationList: [
        {
          name: "产品中心",
          chirdren: [
            {
              name: "智能通行",
              url: "/product",
              index: 1,
              type: 1,
            },
            {
              name: "自助服务",
              url: "/product",
              index: 2,
              type: 1,
            },
            {
              name: "实名核验",
              url: "/product",
              index: 3,
              type: 1,
            },
            {
              name: "数据分析",
              url: "/product",
              index: 4,
              type: 1,
            },
          ],
        },
        {
          name: "解决方案",
          chirdren: [
            {
              name: "智慧园区",
              url: "/park",
              type: 2,
            },
            {
              name: "智慧校园",
              url: "/campus",
              type: 2,
            },
            {
              name: "智慧交通",
              url: "/traffic",
              type: 2,
            },
            {
              name: "智慧工地",
              type: 2,
              url: "/construction",
            },
            {
              name: "智慧厅店",
              type: 2,
              url: "/construction",
            },
            {
              name: "智慧新零售",
              type: 2,
              url: "/construction",
            },
            {
              name: "智慧公租房",
              url: "/public",
              type: 2,
            },
          ],
        },
        {
          name: "开放平台",
          chirdren: [
            {
              name: "人员管理平台",
              url: "http://web.verification.srthinker.com/",
              type: 3,
            },
            {
              name: "证码核验平台",
              url: "http://web.antiepidemic.srthinker.com/",
              type: 3,
            },
          ],
        },
      ],
    };
  },

  methods: {
    // 跳转页面
    skipUrl(item) {
      if (!item.url) {
        return;
      }

      switch (item.type) {
        case 1:
          this.$router.replace({
            path: item.url,
            query: {
              index: item.index,
            },
          });
          break;
        case 2:
          this.$router.replace({
            path: item.url,
          });
          break;

        case 3:
          window.open(item.url, "_blank");
          break;
      }
    },

    // 跳转备案页面
    record() {
      window.location.href = "https://beian.miit.gov.cn";
    },
  },
};
</script>

<style scoped>
.underside {
  width: 100%;
  height: 33.25rem;
  background: #272727ff;
}
.underside_box {
  width: 101.8125rem;
  margin: 0 auto;
}
.underside_navi {
  width: 100%;
  padding-top: 5rem;
  box-sizing: border-box;
  height: 27.875rem;
  display: flex;
  align-items: flex-start;
  border-bottom: 0.0625rem solid #919497;
}
.underside_navi_item {
  margin-right: 12.5rem;
}
.underside_navi_headline {
  font-size: 1rem;
  color: white;
  text-align: left;
  margin-bottom: 1.25rem;
}
.underside_navi_subheading {
  font-size: 1rem;
  color: #919497ff;
  cursor: pointer;
  text-align: left;
  margin-bottom: 1.25rem;
}
.underside_company {
  margin-left: 15.8125rem;
}
.underside_filings {
  display: flex;
  width: 100%;
  height: 5.3125rem;
  align-items: center;
  font-size: 1rem;
  color: #919497ff;
}
.underside_copyright {
  margin-right: 3.75rem;
}
.underside_filings_icon {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.3125rem;
}
.record {
  cursor: pointer;
}
</style>