<template>
  <div>
    <div class="linkage">
      <div class="content-box">
        <div class="nav" ref="nav">
          <div v-for="item in btnList" :key="item.id" class="ac">
            {{ item.time }}
            <div class="ac_content">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="blank"></div>
      <div class="nav_b">
        2009.05
        <div class="ac_content">北京华汇成⽴， 主营苹果周边⽀付配件</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      now: 0,
      btnList: [
        {
          time: "2023.04",
          content: "华汇成为“数字广州”产业发展联盟理事会员单位",
        },
        {
          time: "2023.02",
          content: "广州数字防疫入围厂商，取得广州市防疫数字哨兵查验终端资质",
        },
        {
          time: "2021.06",
          content:
            "华汇⾃研实名制核验健康码，加快核验速度通行登记，助力疫情科技防控，便于人员流调管理",
        },
        {
          time: "2021.01",
          content: "新冠疫情爆发，华汇自研实名制登记系统上线",
        },
        {
          time: "2019.06",
          content:
            "华汇成立，主营物联网类智能硬件产品，为行业提供软硬件及平台综合解决方案",
        },
        {
          time: "2017.11",
          content: "⽆纸化办公产品发布，在运营商⼴泛应⽤",
        },
        {
          time: "2015.08",
          content: "实名制SAAS化产品⽅案发布，在运营商及应⽤领域⼴泛应⽤",
        },
        {
          time: "2012.04",
          content: "⼴州华汇成⽴，主营实名制及⽆纸化产品解决⽅案",
        },
      ],
      content: [
        {
          name: "2月",
          content: "广州数字防疫入围厂商，取得广州市防疫数字哨兵查验终端资质",
        },
        {
          name: "4月",
          content: "华汇成为“数字广州”产业发展联盟理事会员单位",
        },
      ], //内容
    };
  },
  methods: {
    // 切换
    nav(item, index) {
      this.now = index;
      this.content = item.month;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 2.5rem;
  color: #030303ff;
  font-weight: bold;
}
.subtitle {
  font-size: 1.5rem;
  margin-top: 1.1875rem;
  color: #333333ff;
}

.linkage {
  padding-left: 1.9375rem;
  margin-bottom: 3.75rem;
  box-sizing: border-box;
}
.nav {
  height: 52rem;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  border-left: 0.25rem solid #eaeaeaff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.blank {
  padding-left: 1.3125rem;
  height: 2.875rem;
  border-left: 0.25rem solid #eaeaeaff;
}

.ac {
  font-size: 1.125rem;
  color: #000000ff;
  position: relative;
  font-weight: bold;
  text-align: left;
}
.ac::after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background: #ffffff;
  top: 0.05rem;
  left: -2rem;
  position: absolute;
  border-radius: 50%;
  border: 0.125rem solid #60a6ec;
}
.ac_content {
  font-size: 0.9375rem;
  color: #333333;
  font-weight: 400;
  width: 18.25rem;
}
.nav_b::after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background: #ffffff;
  top: 0.125rem;
  left: -0.3rem;
  position: absolute;
  border-radius: 50%;
  border: 0.125rem solid #60a6ec;
}
.nav_b {
  font-size: 1.125rem;
  color: #000000ff;
  position: relative;
  font-weight: bold;
  text-align: left;
  padding-left: 1.5125rem;
  top: -0.3125rem;
}
.content-box {
  display: flex;
  align-items: flex-start;
}

.hwtitle {
  width: 18.25rem;
  height: 3.5rem;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: white;
}
</style>
