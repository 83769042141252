<template>
  <div>
    <div class="title">助力服务 提升价值</div>
    <div class="subtitle">
      华汇⼀直秉承“⾃主研发”理念，为客户提供⼀流的产品、技术与服务
    </div>
    <img src="@/assets/about/course.png" class="course" />
    <!-- <div class="linkage">
      <div class="content-box">
        <div class="nav" ref="nav">
          <div
            @mouseover="nav(item, index)"
            v-for="(item, index) in btnList"
            :key="item.id"
            :class="now == index ? 'ac' : 'nav-list'"
          >
            {{ item.time }}
          </div>
        </div>
        <div class="content" ref="content">
          <div
            class="content-list"
            v-for="(cont, index) in content"
            :key="index"
          >
            <div class="hwtitle">
              <div class="hwtitle_name">{{ cont.name }}</div>
              <div class="hwtitle_content">{{ cont.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      now: 0,
      btnList: [
        {
          time: "2023",
          month: [
            {
              name: "2月",
              content:
                "广州数字防疫入围厂商，取得广州市防疫数字哨兵查验终端资质",
            },
            {
              name: "4月",
              content: "华汇成为“数字广州”产业发展联盟理事会员单位",
            },
          ],
        },
        {
          time: "2021",
          month: [
            {
              name: "1月",
              content: "新冠疫情爆发，华汇自研实名制登记系统上线",
            },
            {
              name: "6月",
              content:
                "华汇⾃研实名制核验健康码，加快核验速度通行登记，助力疫情科技防控，便于人员流调管理",
            },
          ],
        },
        {
          time: "2019",
          month: [
            {
              name: "6月",
              content:
                "华汇成立，主营物联网类智能硬件产品，为行业提供软硬件及平台综合解决方案",
            },
          ],
        },
        {
          time: "2017",
          month: [
            {
              name: "6月",
              content: "⽆纸化办公产品发布，在运营商⼴泛应⽤",
            },
          ],
        },
        {
          time: "2015",
          month: [
            {
              name: "8月",
              content: "实名制SAAS化产品⽅案发布，在运营商及应⽤领域⼴泛应⽤",
            },
          ],
        },
        {
          time: "2012",
          month: [
            {
              name: "4月",
              content: "⼴州华汇成⽴，主营实名制及⽆纸化产品解决⽅案",
            },
          ],
        },
        {
          time: "2009",
          month: [
            {
              name: "5月",
              content: "北京华汇成⽴， 主营苹果周边⽀付配件",
            },
          ],
        },
      ],
      content: [
        {
          name: "2月",
          content: "广州数字防疫入围厂商，取得广州市防疫数字哨兵查验终端资质",
        },
        {
          name: "4月",
          content: "华汇成为“数字广州”产业发展联盟理事会员单位",
        },
      ], //内容
    };
  },
  methods: {
    // 切换
    nav(item, index) {
      this.now = index;
      this.content = item.month;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 2.5rem;
  color: #030303ff;
  font-weight: bold;
}
.subtitle {
  font-size: 1.5rem;
  margin-top: 1.1875rem;
  color: #333333ff;
}
.course {
  width: 102.0625rem;
  height: 39.6875rem;
  display: block;
  margin: 3.75rem auto 7.5rem;
}
.linkage {
  padding-left: 16.4375rem;
  margin-bottom: 3.75rem;
  box-sizing: border-box;
}
.nav {
  height: 61.375rem;
  padding-left: 2.9375rem;
  box-sizing: border-box;
  border-left: .25rem solid #eaeaeaff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ac {
  font-size: 3.375rem;
  color: #000000ff;
  position: relative;
  font-weight: bold;
  letter-spacing: -0.125rem;
}
.ac::after {
  content: "";
  width: .25rem;
  height: 3.9375rem;
  background: #60a6ec;
  top: 0;
  left: -3.1875rem;
  position: absolute;
}
.nav-list {
  font-size: 2.75rem;
  color: #aaaaaaff;
  cursor: pointer;
  letter-spacing: -0.125rem;
}
.content-box {
  display: flex;
  align-items: flex-start;
}
.content {
  margin-left: 10rem;
  width: 76.1875rem;
  padding: 3.75rem 0;
  box-sizing: border-box;
  background: #fafbffff;
}
.hwtitle {
  width: 68.6875rem;
  height: 14rem;
  margin: 0 auto;
  display: flex;
  padding-left: 3.75rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: white;
}
.content-list {
  margin-bottom: 2.5rem;
}
.hwtitle_name {
  font-size: 2.5rem;
  color: #60a6ecff;
  font-weight: bold;
}
.hwtitle_content {
  font-size: 1.25rem;
  color: #333333ff;
  margin-top: 1.875rem;
  padding-left: 2.75rem;
  box-sizing: border-box;
  position: relative;
}
.hwtitle_content::after {
  content: "";
  width: .875rem;
  height: .875rem;
  background: #60a6ec;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
</style>
